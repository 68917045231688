<template>
	<v-container class="questionnaire">
		<v-row>
			<v-card class="pa-4 elevation-0" width="100%" height="100%">
				<v-card-title class="d-flex flex-column align-start subtitle-1">
					<h3>
						Анкета для диспетчерской службы
					</h3>
					<h5>
						Прорабатывайте вопросы и ответы для повышения качества обслуживания
					</h5>
				</v-card-title>

				<v-card-text>
					<!-- Фильтры -->
					<v-row>
						<v-col cols="12" sm="8">
							<!-- Поиск по ключевым -->
							<v-text-field
								v-model="params.name"
								class="border-all"
								placeholder="Поиск по ключевым словам"
								flat
								dense
								solo
								hide-details
								clearable
								prepend-inner-icon="mdi-magnify"
								@input="searchByKeyword"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="4">
							<!-- Категория -->
							<v-autocomplete
								v-model="params.category_id"
								class="border-all"
								placeholder="Категория"
								prepend-inner-icon="mdi-filter"
								hide-details
								dense
								solo
								flat
								clearable
								:items="catalog.request_categories"
								item-text="name"
								item-value="id"
								@change="loadData"
							>
							</v-autocomplete>
						</v-col>
					</v-row>

					<!-- Кнопки -->
					<div class="d-flex justify-space-between mt-3 mb-6">
						<Button @click.native="showCreateModal = true">
							Добавить вопрос
						</Button>
					</div>

					<!-- Loading -->
					<v-row v-if="loading === true">
						<v-col cols="12" v-for="(q, i) in 5" :key="i">
							<v-skeleton-loader
								height="120px"
								type="image"
							></v-skeleton-loader>
						</v-col>
					</v-row>

					<!-- Questions -->
					<div v-else-if="questions.length" class="questions">
						<div
							class="questions__card mb-12 pb-2"
							v-for="(q, i) of questions"
							:key="q._id"
						>
						<!-- <pre>{{q}}</pre> -->
							<v-progress-linear
								v-if="loading === q._id"
								indeterminate
								color="primary"
							></v-progress-linear>

							<div class="questions__header mb-2">
								<div class="d-flex flex-column">
									<v-row>
										<v-col class="d-flex align-center" cols="5">
											<h4 class="mb-0">
												{{ `${i + 1}. ${q.name}` }}
											</h4>
										</v-col>

										<v-spacer></v-spacer>
										<!-- Поиск по адресу -->
										<!-- <v-col cols="2">
											<v-autocomplete
												v-model="q.house_id"
												class="border-bottom rounded-0 green lighten-2 text-green"
												placeholder="Дом"
												hide-details
												dense
												solo
												flat
												:filter="addressFilter"
												:items="compHouses"
												item-text="apartment_address"
												item-value="_id"
												@change="loadSingleAnswer(q)"
											>
											</v-autocomplete>
										</v-col> -->
										<!-- Категория -->
										<v-col cols="2">
											<v-autocomplete
												v-model="q.category.id"
												class="border-bottom rounded-0 green text-green"
												placeholder="Категория"
												solo
												hide-details
												dense
												flat
												:items="catalog.request_categories"
												item-text="name"
												item-value="id"
												append-icon=""
												@change="saveChanges(q, 'question')"
											>
											</v-autocomplete>
										</v-col>
										<v-col class="d-flex" cols="1">
											<v-btn
												color="primary"
												icon
												@click="isEditingAnswer = q.id"
											>
												<v-icon>mdi-pencil</v-icon>
											</v-btn>
											<v-btn color="red" icon @click="toggleConfirmModal(q)">
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</div>
							</div>
							<div class="questions__content">
								<div v-if="!q.answers.length">
									<v-textarea
										v-model="newAnswer"
										class="border-all"
										rows="1"
										placeholder="Введите ответ на вопрос"
										solo
										hide-details
										dense
										flat
										@click.stop=""
										@input="saveAnswer(q)"
									></v-textarea>
									<v-btn
										v-if="isEditingAnswer === q.id"
										class="subtitle-2 text-capitalize mt-2"
										color="primary"
										small
										depressed
										:outlined="isSaving === 2"
										:loading="isSaving === 1"
										:disabled="isSaving > 1"
										@click="closeEditing(q)"
									>
										<span v-if="isSaving === 2">Сохранено</span>
										<span v-else>Сохранить</span>
									</v-btn>
								</div>
								<div v-for="(answer, i) in q.answers" :key="i">
									<div
										v-if="answer.name && isEditingAnswer !== q.id"
										class="pb-0 mt-0"
									>
										<span>{{ answer.name }}</span>
									</div>
									<v-textarea
										v-else
										v-model="answer.name"
										class="border-all"
										rows="1"
										placeholder="Введите ответ на вопрос"
										solo
										hide-details
										dense
										flat
										@click.stop=""
										@input="saveAnswer(q, answer)"
									></v-textarea>

									<v-btn
										v-if="isEditingAnswer === q.id"
										class="subtitle-2 text-capitalize mt-2"
										color="primary"
										small
										depressed
										:outlined="isSaving === 2"
										:loading="isSaving === 1"
										:disabled="isSaving > 1"
										@click="closeEditing(q, answer)"
									>
										<span v-if="isSaving === 2">Сохранено</span>
										<span v-else>Сохранить</span>
									</v-btn>
								</div>
							</div>
						</div>
					</div>


					<v-pagination
						v-if="!loading && questions.length"
						v-model="options.page"
						:length="options.pages"
						:total-visible="7"
						@input="changePage"
					></v-pagination>
				</v-card-text>
			</v-card>
		</v-row>

		<FaqModal
			v-if="showCreateModal"
			@close-modal="showCreateModal = false"
			@update-info="loadData"
		/>

		<ModalConfirm
			v-if="showConfirmModal"
			@close-modal="deleteAnswer"
			title="Удаление ответа"
		>
			<template #body>
				<div class="d-flex flex-column justify-center text-center align-center">
					<span>
						Вы действительно хотите удалить ответ на вопрос
					</span>
					<strong>{{ toDelete.name }}?</strong>
				</div>
			</template>
		</ModalConfirm>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";

import FaqModal from "@/components/FaqModal";
import ModalConfirm from "@/components/app/ModalConfirm";

export default {
	name: "Questionnaire",

	components: {
		FaqModal,
		ModalConfirm,
	},

	watch: {
		"params.name"(val) {
			if (val === "") {
				this.params.name = null;
			}
		},
	},

	data() {
		return {
			isSaving: 0,
			params: {
				category_id: null,
				name: null,
			},
			options: {
				pages: 1,
				page: +this.$route.params.page || 1,
			},
			loading: false,
			opened: [],
			questions: [],
			saveTimer: null,
			searchTimer: null,
			timer: null,
			showCreateModal: false,
			showConfirmModal: false,
			isEditingAnswer: null,
			toDelete: null,
			newAnswer: '',
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
			kskId: "auth/GET_KSK_ID",
			userData: "auth/GET_INDIVIDUAL_DATA",
		}),

		compHouses() {
			return [{ apartment_address: "Все", _id: null }, ...this.houses];
		},

		saveBtnColor() {
			return this.isSaving === 0
				? "primary"
				: this.isSaving === 1
				? "secondary"
				: "info";
		},
		saveBtnText() {
			return this.isSaving === 0
				? "Сохранить"
				: this.isSaving === 1
				? "Сохранение..."
				: "Изменения сохранены";
		},
	},

	watch: {
		isSaving(val) {
			if (val !== 2) return;
			clearTimeout(this.timer);
			this.timer = setTimeout(() => (this.isSaving = 0), 2000);
		},
	},

	methods: {
		async closeEditing(q, answer) {
			clearTimeout(this.saveTimer);
			await this.saveChanges(q, "answer", answer);
			this.isEditingAnswer = null;
		},

		toggleConfirmModal(q) {
			this.toDelete = q;
			this.showConfirmModal = true;
		},

		async loadSingleAnswer(q) {
			try {
				this.loading = q._id;

				const params = { question_id: q._id, house_id: q.house_id || null };
				const res = await this.$api.ksk.load_answer(params);

				if (!q.answer) q.answer = { name: "" };

				if (res.error) {
					q.answer = { name: "" };
					return;
				}

				q.answer = res;
			} catch (error) {
				throw error;
			} finally {
				this.loading = false;
			}
		},

		async loadAnswers() {
			try {
				for (const q of this.questions) {
					const params = { question_id: q._id, house_id: q.house_id || null };
					const res = await this.$api.ksk.load_answer(params);

					if (!q.house_id) q.house_id = null;

					q.answer = { name: "" };

					if (res.error) continue;

					q.answer = res;
				}
			} catch (error) {
				throw error;
			}
		},

		async loadData() {
			try {
				this.loading = true;
				const res = await this.$api.ksk.load_questions({
					...this.params,
					page: this.options.page,
					company_ids: [this.kskId]
				});
				this.questions = res.records;
				this.options.pages = res.pages;
				this.options.page = res.page;
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},

		changePage() {
			this.$router.replace({ query: { page: this.options.page } });
			this.loadData();
		},

		async deleteAnswer(isConfirmed) {
			this.showConfirmModal = false;

			if (!isConfirmed) return;

			try {
				const res = await this.$api.ksk.delete_question(this.toDelete.id);
				this.$root.snackbar.show({ message: res.success });
				this.loadData();
			} catch (error) {
				this.$root.snackbar.show({ message: error.message, color: "error" });
			}
		},

		saveAnswer(q, answer) {
			console.log('q', q)
			console.log('answer', answer)
			clearTimeout(this.saveTimer);
			this.isEditingAnswer = q.id;
			this.saveTimer = setTimeout(() => {
				this.saveChanges(q, "answer", answer);
			}, 2000);
		},

		// * @param type {'answer', 'question'}
		async saveChanges(q, type = "question", answer) {
			console.log(q);
			console.log(answer);
			let data = {
				question_id: q.id,
				house_id: answer?.house || null,
				company_id: this.kskId
			};

			if (type === "question") {
				data = {
					name: q.name,
					company_id: this.kskId,
					category_id: q.category.id,
					_method: 'patch',
				}
			} else if(answer) {
				data.name = answer.name || "";
				data._method = 'patch'
			} else {
				data.name = this.newAnswer
			}

			const id = type === "answer" ? answer?.id : q.id;

			try {
				this.isSaving = 1;
				if(data._method) {
					const res = await this.$api.ksk[`edit_${type}`]({ id, data });
				} else if (type === "answer") {
					await this.$api.ksk.add_answer(data);
					this.loadData()
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.isSaving = 2;
			}
		},

		async searchByKeyword() {
			if (this.params.name === "") this.params.name = null;
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(() => this.loadData(), 1000);
		},
	},

	mounted() {
		this.loadData();
	},
};
</script>

<style lang="scss" scoped>
.questionnaire {
	height: 90vh;
}
.text-green {
	color: #2eb85c !important;
}
.questions__card {
	border-bottom: 1px solid #666;
}
</style>
